import React from 'react'
import { Box } from '@material-ui/core'
import { SpinnerCard } from '../common/Layout'
import { CreditsEarned } from './CreditsEarned'
import { CustomersOrdered } from './CustomersOrdered'

export const CoworkersAndCreditsCard = ({
  order,
  friendsOrdered,
  deliveryDropoff,
  isLoadingFriendsOrders,
  loading,
  isLoadingFavorites,
  submitting,
  avatarId,
  firstName,
  lastName,
  deliveryDropoffRewards,
  rewardsEligibleSubtotalInCents,
  rewardsDistributed,
  loadingOrderRewards,
  currentRewardsInCents,
  currentRewardsPercentage,
}) => {
  const showCreditsEarnedSection =
    (order &&
      deliveryDropoff &&
      deliveryDropoff.type === 'Reserve' &&
      order.reserveCreditsEarned != null) ||
    (order && order.rewardsCreditsEarned != null)

  return (
    <SpinnerCard
      spinnerActive={
        !order ||
        !friendsOrdered ||
        !deliveryDropoff ||
        isLoadingFriendsOrders ||
        loading ||
        isLoadingFavorites ||
        submitting ||
        loadingOrderRewards
      }
      style={{ height: showCreditsEarnedSection ? '27.5em' : '29.5em' }}
    >
      <Box display={'flex'} flexDirection={'column'} height={'25.4em'} maxHeight={'25.4em'}>
        {showCreditsEarnedSection && (
          <CreditsEarned
            deliveryDropoffRewards={deliveryDropoffRewards}
            reserveCreditsEarned={order.reserveCreditsEarned}
            reserveDeliveryFee={order.reserveDeliveryFee}
            currentRewardsInCents={currentRewardsInCents}
            currentRewardsPercentage={currentRewardsPercentage}
            rewardsEligibleSubtotalInCents={rewardsEligibleSubtotalInCents}
            countOfOrdersOnDropoff={order.countOfOrdersOnDropoff}
            rewardsDistributed={rewardsDistributed}
          />
        )}
        {order && friendsOrdered && (
          <CustomersOrdered
            friendsOrders={
              friendsOrdered.find(f => f.dropoffId === order.deliveryDropoffId)?.friendsOrders ?? []
            }
            avatarId={avatarId}
            firstName={firstName}
            lastName={lastName}
            creditsEarnedSectionShown={showCreditsEarnedSection}
          />
        )}
      </Box>
    </SpinnerCard>
  )
}
