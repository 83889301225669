import appboy from 'appboy-web-sdk'

export const BRAZE_CUSTOM_EVENTS = Object.freeze({
  ORDER_COMPLETED: 'order-completed',
  REFERRAL_COPIED_LINK: 'referral-copied-link',
  ADDED_TO_CART: 'added-to-cart',
  GO_ELIGIBLE: 'go-eligible',
})

export const BRAZE_MESSAGE_IDS = Object.freeze({
  PUSH_PRIMER: 'push-primer',
})

export const publishBrazeEvent = (event, properties) => {
  appboy.logCustomEvent(event, properties)
}

export const interceptInAppMessageToAskToShowWebPushNotifications = inAppMessage => {
  let shouldDisplay = true

  if (inAppMessage instanceof appboy.InAppMessage) {
    let messageId = inAppMessage.extras['msg-id']

    if (messageId === BRAZE_MESSAGE_IDS.PUSH_PRIMER) {
      if (appboy.isPushPermissionGranted()) {
        appboy.registerAppboyPushMessages()
        shouldDisplay = false
      }
      if (!appboy.isPushSupported() || appboy.isPushBlocked()) {
        shouldDisplay = false
      }
      if (inAppMessage.buttons[0] != null) {
        inAppMessage.buttons[0].subscribeToClickedEvent(() => {
          appboy.registerAppboyPushMessages()
        })
      }
    }
  }

  if (shouldDisplay) {
    appboy.display.showInAppMessage(inAppMessage)
  }
}
