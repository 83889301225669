import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircleRounded } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: '#ff0000',
    height: '2px',
    width: '61.8%',
  },
  listItemRoot: {
    minWidth: 35,
  },
  icon: {
    color: '#ff0000',
  },
  point: {
    fontSize: '20px',
  },
  title: {
    fontWeight: 'bold',
  },
}))

export const RegistrationPoints = ({ ...rest }) => {
  const classes = useStyles()

  return (
    <Box {...rest}>
      <Typography className={classes.title} gutterBottom variant="h4">
        A better & safer lunch delivery experience
      </Typography>
      <Divider className={classes.divider} />
      <List aria-label="foodsby points" component="nav" dense>
        <ListItem disableGutters alignItems="flex-start">
          <ListItemIcon classes={{ root: classes.listItemRoot }}>
            <CheckCircleRounded className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography className={classes.point} variant="body1">
                Normal menu prices and just $1.99 delivery fee
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters alignItems="flex-start">
          <ListItemIcon classes={{ root: classes.listItemRoot }}>
            <CheckCircleRounded className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography className={classes.point} variant="body1">
                Restaurants prepare and deliver the food contact-free
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters alignItems="flex-start">
          <ListItemIcon classes={{ root: classes.listItemRoot }}>
            <CheckCircleRounded className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography className={classes.point} variant="body1">
                Curated list of popular local and national restaurants each day
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Box>
  )
}

export default RegistrationPoints
