import { format, subDays } from 'date-fns'
import { set } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DialogDeliveryInstructions from '../components/checkout/DialogDeliveryInstructions'
import { closeAutoFavorite } from '../redux/modules/checkout'
import { loadDeliveryDropoffStart, loadDeliveryRewardsStart } from '../redux/modules/delivery'
import { loadFavoriteStart } from '../redux/modules/favorite'
import {
  toggleDeliveryInstructionsDialog,
  updateUserSavedLocationStart,
} from '../redux/modules/location'
import { loadMenuStart } from '../redux/modules/menu'
import {
  loadOrderByIdStart,
  loadOrderStart,
  loadOrderRewardsByIdStart,
} from '../redux/modules/orderDetail'
import { loadDeliveryDropoffRewardsStart } from '../redux/modules/deliveryDropoffRewards'
import {
  selectCurrentLocationWithAddress,
  selectCurrentSavedLocation,
} from '../redux/modules/selectors'
import { loadReasonsByOrder, submitTicket } from '../redux/modules/ticket'
import { loadOfficesStart } from '../redux/modules/offices'
import { selectOfficeAccounts } from '../redux/selectors/accountSelectors'
import { getFriendsOrdersStart } from '../redux/modules/friends'
import { ConfirmationComponent } from '../components/confirmation/ConfirmationComponent'

class PageConfirmation extends Component {
  constructor(props) {
    super(props)
    const {
      loadOrderByIdStart,
      loadReasonsByOrder,
      loadOrderRewardsByIdStart,
      match: {
        params: { orderId },
      },
    } = this.props
    loadOrderByIdStart(orderId)
    loadReasonsByOrder(orderId)
    loadOrderRewardsByIdStart(orderId)
    const timer = setInterval(() => loadOrderByIdStart(orderId), 600000)
    this.state = { showOrderSummary: false, timer }
  }

  componentDidMount() {
    let {
      selectedDate,
      loadOrderStart,
      match: {
        params: { locationId },
      },
      loadOfficesStart,
      getFriendsOrdersStart,
      loadDeliveryRewardsStart,
      loadDeliveryDropoffRewardsStart,
      order,
    } = this.props
    loadOrderStart(format(selectedDate, 'YYYYMMDD'), locationId)
    loadOfficesStart(locationId)

    if (order) {
      let orderId = order.orderId
      loadOrderRewardsByIdStart(orderId)
      loadDeliveryDropoffRewardsStart(order.deliveryDropoffId)
      getFriendsOrdersStart(locationId, format(subDays(order.deliveryDate, 0), 'YYYY-MM-DD'))
    }

    loadDeliveryRewardsStart(locationId, format(selectedDate, 'YYYYMMDD'))
    window && window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    let {
      loadDeliveryDropoffStart,
      loadDeliveryDropoffRewardsStart,
      loadOrderRewardsByIdStart,
      loadFavoriteStart,
      loadMenuStart,
      loadOrderStart,
      loadOfficesStart,
      getFriendsOrdersStart,
      match: {
        params: { locationId },
      },
      order,
    } = this.props
    if ((!prevProps.order && order) || (order && order.orderId !== prevProps.order.orderId)) {
      loadOrderStart(format(order.orderDate, 'YYYYMMDD'), locationId)
      loadMenuStart(order.deliveryDropoffId)
      loadDeliveryDropoffStart(order.deliveryDropoffId)
      loadDeliveryDropoffRewardsStart(order.deliveryDropoffId)
      loadOrderRewardsByIdStart(order.orderId)
      loadFavoriteStart(order.menuId)
      loadOfficesStart(locationId)
      getFriendsOrdersStart(locationId, format(subDays(order.deliveryDate, 0), 'YYYY-MM-DD'))
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.timer)
  }

  toggleShowOrderSummary = () => {
    this.setState({ showOrderSummary: !this.state.showOrderSummary })
  }

  render() {
    const {
      autoFavorite,
      closeAutoFavorite,
      curSavedLocation,
      favoriteInfo,
      history,
      deliveryDropoff,
      deliveryDropoffRewards,
      isCheckingStatus,
      isLoadingFavorites,
      isUpdatingSavedLocation,
      isWebpSupported,
      loading,
      match: {
        params: { locationId },
      },
      order,
      orders,
      profile,
      reasons,
      showDeliveryInstructionsDialog,
      submitTicket,
      submitting,
      toggleDeliveryInstructionsDialog,
      updateSavedLocationError,
      updateUserSavedLocationStart,
      updatingModal,
      location,
      friendsOrdered,
      avatarId,
      firstName,
      lastName,
      isLoadingFriendsOrders,
      rewards,
      isLoadingRewards,
      currentRewardsInCents,
      currentRewardsPercentage,
      rewardsEligibleSubtotalInCents,
      rewardsDistributed,
      errorLoadingOrderRewards,
      loadingOrderRewards,
    } = this.props

    let autoFavorites = []
    order &&
      favoriteInfo &&
      favoriteInfo.forEach(info => {
        autoFavorites = autoFavorites.concat(
          order.orderItems.filter(item => item.menuItemId === info.menuItemId),
        )
      })
    return (
      <>
        <ConfirmationComponent
          location={location}
          friendsOrdered={friendsOrdered}
          avatarId={avatarId}
          firstName={firstName}
          lastName={lastName}
          autoFavorite={autoFavorite}
          autoFavorites={autoFavorites}
          closeAutoFavorite={closeAutoFavorite}
          history={history}
          isCheckingStatus={isCheckingStatus}
          isLoadingFriendsOrders={isLoadingFriendsOrders}
          isLoadingFavorites={isLoadingFavorites}
          isWebpSupported={isWebpSupported}
          loading={loading}
          locationId={locationId}
          order={order}
          orders={orders}
          profile={profile}
          reasons={reasons}
          showOrderSummary={this.state.showOrderSummary}
          submitTicket={submitTicket}
          submitting={submitting}
          toggleShowOrderSummary={this.toggleShowOrderSummary}
          updatingModal={updatingModal}
          deliveryDropoff={deliveryDropoff}
          rewards={rewards}
          isLoadingRewards={isLoadingRewards}
          currentRewardsInCents={currentRewardsInCents}
          currentRewardsPercentage={currentRewardsPercentage}
          rewardsEligibleSubtotalInCents={rewardsEligibleSubtotalInCents}
          rewardsDistributed={rewardsDistributed}
          deliveryDropoffRewards={deliveryDropoffRewards}
          errorLoadingOrderRewards={errorLoadingOrderRewards}
          loadingOrderRewards={loadingOrderRewards}
        />
        <DialogDeliveryInstructions
          curSavedLocation={curSavedLocation}
          isUpdatingSavedLocation={isUpdatingSavedLocation}
          showDeliveryInstructionsDialog={showDeliveryInstructionsDialog}
          showOnEmptyDeliveryInstructions
          toggleDeliveryInstructionsDialog={toggleDeliveryInstructionsDialog}
          updateSavedLocationError={updateSavedLocationError}
          updateUserSavedLocationStart={updateUserSavedLocationStart}
        />
      </>
    )
  }
}

const mapStateToProps = (state, entryProps) => {
  const {
    loading,
    order,
    orders,
    currentRewardsInCents,
    currentRewardsPercentage,
    rewardsEligibleSubtotalInCents,
    rewardsDistributed,
    errorLoadingOrderRewards,
    loadingOrderRewards,
  } = state.orderDetail
  const { profile, updatingModal } = state.profile
  const { isLoadingFavorites } = state.favorite
  const { favoriteInfo } = state.checkout
  const { submitting } = state.ticket
  const { reasons } = state
  const { isWebpSupported } = state.browser
  const {
    isUpdatingSavedLocation,
    showDeliveryInstructionsDialog,
    updateSavedLocationError,
  } = state.location
  const deliveryLocationId = entryProps.match.params.locationId
  const curSavedLocation = selectCurrentSavedLocation(
    set(state, 'location.location.deliveryLocationId', deliveryLocationId),
  )
  const location = selectCurrentLocationWithAddress(state)
  const { friendsOrdered, isLoadingFriendsOrders } = state.friends
  const offices = selectOfficeAccounts(state)
  const { selectedDate, deliveryDropoff, rewards, isLoadingRewards } = state.delivery
  const deliveryDropoffRewards = state.deliveryDropoffRewards
  const {
    profile: { avatarId, firstName, lastName },
  } = state.profile

  // eslint-disable-next-line no-console
  console.log(deliveryDropoffRewards)

  return {
    curSavedLocation,
    location,
    friendsOrdered,
    isLoadingFriendsOrders,
    avatarId,
    firstName,
    lastName,
    offices,
    selectedDate,
    deliveryDropoff,
    favoriteInfo,
    isLoadingFavorites,
    isUpdatingSavedLocation,
    isWebpSupported,
    loading,
    order,
    rewards,
    isLoadingRewards,
    orders,
    profile,
    reasons,
    showDeliveryInstructionsDialog,
    submitting,
    updateSavedLocationError,
    updatingModal,
    currentRewardsInCents,
    currentRewardsPercentage,
    rewardsEligibleSubtotalInCents,
    rewardsDistributed,
    deliveryDropoffRewards,
    errorLoadingOrderRewards,
    loadingOrderRewards,
  }
}

const mapDispatchToProps = {
  closeAutoFavorite,
  loadDeliveryDropoffStart,
  loadDeliveryRewardsStart,
  loadFavoriteStart,
  loadMenuStart,
  loadOrderByIdStart,
  loadOrderRewardsByIdStart,
  loadDeliveryDropoffRewardsStart,
  loadOrderStart,
  loadOfficesStart,
  getFriendsOrdersStart,
  loadReasonsByOrder,
  submitTicket,
  toggleDeliveryInstructionsDialog,
  updateUserSavedLocationStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageConfirmation)
