import React from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'

import { SIGNUP_LOGIN_TABS } from '../../login/DialogLoginOrJoin'
import { loginRoute, registerRoute } from '../../../routes/routes'

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(2),
  },
}))

const PublicMenu = ({ linkMode }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = queryParams => {
    dispatch(push({ search: queryString.stringify(queryParams) }))
  }

  return (
    <Box display="flex" flexDirection="row">
      {linkMode && (
        <Link component={Button} to={registerRoute.path} className={classes.button}>
          Sign up
        </Link>
      )}
      {!linkMode && (
        <Button
          className={classes.button}
          onClick={() =>
            handleClick({ authenticate: 'true', authenticateTab: SIGNUP_LOGIN_TABS.SIGNUP })
          }
        >
          Sign up
        </Button>
      )}

      {linkMode && (
        <Link component={Button} to={loginRoute.path} className={classes.button}>
          Log in
        </Link>
      )}
      {!linkMode && (
        <Button
          className={classes.button}
          onClick={() =>
            handleClick({ authenticate: 'true', authenticateTab: SIGNUP_LOGIN_TABS.LOGIN })
          }
        >
          Log in
        </Button>
      )}
    </Box>
  )
}

export default PublicMenu
