export const getEntryPoint = (referral, team) => {
  if (referral) {
    return 'REFERRAL'
  }

  if (team) {
    return 'EMPLOYER_UPLOAD'
  }

  return null
}
