import { isEmpty, isString } from 'lodash'
import { useEffect, useState } from 'react'

import { api } from '../api/api'

export const useCompleteAccountToken = token => {
  const [completeAccountDetails, setCompleteAccountDetails] = useState()
  const [completeAccountError, setCompleteAccountError] = useState()
  const [completeAccountLoading, setCompleteAccountLoading] = useState(false)

  useEffect(() => {
    if (isString(token) && !isEmpty(token)) {
      setCompleteAccountLoading(true)
      api
        .get(`/api/v1/uaa/users/inviteCode/${token}`)
        .then(data => setCompleteAccountDetails(data))
        .catch(error => setCompleteAccountError(error))
        .finally(() => setCompleteAccountLoading(false))
    }
  }, [token])

  return { completeAccountDetails, completeAccountError, completeAccountLoading }
}
