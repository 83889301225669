import { Avatar, Box, Divider, Tooltip, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { AvatarGroup } from '@material-ui/lab'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import currency from 'currency.js'
import { SplitDeliveryFeeNotice } from '../../util/reserveUtils'
import { UNKNOWN_CUSTOMER } from '../../util/constants'
import { findFriendProfileForUser } from './utils/orderUtils'

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'black',
    fontWeight: 400,
    padding: '8px',
    '& p, & a': {
      color: '#fff !important',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}))

const FriendsOrdered = ({ friendImageUrlBase, friendsOrdered }) => {
  const maxFriendBubbles = 3
  const additionalFriendsOrderCount = friendsOrdered.length - maxFriendBubbles

  return (
    <>
      <Typography className="-friendsTitle" variant="body2">
        Ordered by
      </Typography>
      <AvatarGroup className="-avatarGroup" style={{ zIndex: 1 }}>
        {friendsOrdered.slice(0, maxFriendBubbles).map(friendOrdered => {
          const friend = friendOrdered.friend
          const name = `${friend.firstName} ${friend.lastName}`
          const initials =
            `${isEmpty(friend.firstName) ? '?' : friend.firstName[0].toUpperCase()}` +
            `${isEmpty(friend.lastName) ? '?' : friend.lastName[0].toUpperCase()}`
          return (
            <Tooltip key={friend.userId} title={name}>
              <Avatar
                alt={name}
                src={
                  friend.avatarId &&
                  encodeURI(
                    `${friendImageUrlBase}/profiles/${friend.avatarId}.jpg?height=50&width=50`,
                  )
                }
                variant="circular"
              >
                {initials}
              </Avatar>
            </Tooltip>
          )
        })}
        {additionalFriendsOrderCount > 0 && <Avatar>+ {additionalFriendsOrderCount}</Avatar>}
      </AvatarGroup>
    </>
  )
}

const makeCreatedByUserFullName = (currentUserId, createdByUserId, createdByFriendProfile) => {
  if (currentUserId === createdByUserId) {
    return 'You'
  }

  if (!createdByFriendProfile) {
    return UNKNOWN_CUSTOMER
  }

  return `${createdByFriendProfile.firstName} ${createdByFriendProfile.lastName}`
}

const DailyRestaurantDeliveryDetail = ({
  cutoffTime,
  cutoffDisplay,
  dropoffDisplay,
  deliveryFeeContribution,
  createdBy,
  currentUserProfile,
  friendsOrdered,
  friendImageUrlBase,
}) => {
  const shortFormat = 'h:mm A'
  const classes = useStyles()
  const createdByFriendProfile = findFriendProfileForUser(friendsOrdered, createdBy)
  const createdByUserFullName = makeCreatedByUserFullName(
    currentUserProfile?.userId,
    createdBy,
    createdByFriendProfile,
  )

  return (
    <>
      <div className="-soldOutContent">
        <p className="-soldOutTitle">Sold Out!</p>
        <p className="-soldOutCopy">
          This restaurant only accepts a certain amount of orders to ensure quality.
        </p>
      </div>
      <div className="-tooLateContent">
        <p className="-tooLateTitle">Sorry, it’s too late.</p>
        <p className="-tooLateCopy">
          This restaurant stopped accepting orders at {format(cutoffTime, shortFormat)}.
        </p>
      </div>
      <p className="-cutOffContent">Hurry, time is running out!</p>
      <div className="-orderTime">
        <Typography className="-cardDetailTitle" variant="body2">
          Order by
        </Typography>
        <p className="-cardDetail">
          <span style={{ fontWeight: 500 }}>{cutoffDisplay}</span>
        </p>
      </div>
      <Divider className="-divider" flexItem light orientation="vertical" />
      <div className="-deliveryTime">
        <div>
          <Typography className="-cardDetailTitle" variant="body2">
            Est. delivery
          </Typography>
          <p className="-cardDetail">
            <span style={{ fontWeight: 500 }}>{dropoffDisplay}</span>
          </p>
        </div>
      </div>

      <Divider className="-divider" flexItem light orientation="vertical" />
      <div className="-deliveryFee">
        <div>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className="-cardDetailTitle" variant="body2">
              Delivery fee
            </Typography>
            {deliveryFeeContribution > 0 && (
              <Tooltip
                interactive
                disableFocusListener
                disableTouchListener
                title={
                  <SplitDeliveryFeeNotice
                    deliveryFeeContribution={deliveryFeeContribution}
                    createdByName={createdByUserFullName}
                  />
                }
                classes={{ tooltip: classes.customTooltip }}
              >
                <InfoIcon
                  fontSize="small"
                  style={{
                    color: 'ABADB2',
                    marginLeft: '3px',
                    marginBottom: '4px',
                    transform: 'scale(0.8)',
                  }}
                />
              </Tooltip>
            )}
          </Box>
          <p className="-cardDetail">
            <span style={{ fontWeight: 500 }}>
              {currency(deliveryFeeContribution, {
                formatWithSymbol: true,
                precision: deliveryFeeContribution % 1 === 0 ? 0 : 2,
              }).format()}
            </span>
          </p>
        </div>
      </div>
      {!isEmpty(friendsOrdered) && (
        <>
          <Divider className="-divider" flexItem light orientation="vertical" />
          <div className="-friendsOrdered">
            <FriendsOrdered
              friendImageUrlBase={friendImageUrlBase}
              friendsOrdered={friendsOrdered}
            />
          </div>
        </>
      )}
    </>
  )
}

export default DailyRestaurantDeliveryDetail
