import React from 'react'
import { isEmpty } from 'lodash'
import { Box } from '@material-ui/core'
import { formatUrl } from '../../util/formatUtils'
import { placeCateringOrderRoute } from '../../routes/routes'
import SkeletonLoadingDeliverySection from './SkeletonLoadingDeliverySection'
import DeliverySection from './DeliverySection'
import CateringHeader from './CateringHeader'
import LoadingDeliverySectionError from './LoadingDeliverySectionError'
import DeliverySectionCard from './DeliverySectionCard'
import RestaurantLogo from './RestaurantLogo'
import RestaurantName from './RestaurantName'
import CateringRestaurantDeliveryDetails from './CateringRestaurantDeliveryDetails'

const CateringDeliverySection = ({
  cateringStores,
  isLoadingCateringStores,
  errorLoadingCateringStores,
  locationId,
}) => {
  if (!isLoadingCateringStores && isEmpty(cateringStores)) {
    return null
  }

  return (
    <Box style={{ position: 'relative', paddingBottom: 4 }}>
      <DeliverySection HeaderComponent={() => <CateringHeader loading={isLoadingCateringStores} />}>
        {errorLoadingCateringStores && !isLoadingCateringStores && <LoadingDeliverySectionError />}
        {isLoadingCateringStores ? (
          <SkeletonLoadingDeliverySection />
        ) : (
          cateringStores.map(store => (
            <DeliverySectionCard
              key={store.storeId}
              isCardActive={true}
              url={formatUrl(placeCateringOrderRoute.path, {
                storeId: store.storeId,
                locationId: locationId,
              })}
              LogoComponent={() => (
                <RestaurantLogo restaurantName={store.restaurantName} logoLink={store.logoUrl} />
              )}
              HeaderComponent={() => (
                <RestaurantName name={store.restaurantName} favoritesCount={0} />
              )}
              SubheaderComponent={() => (
                <CateringRestaurantDeliveryDetails
                  leadTime={store.cateringLeadTime}
                  maxWeight={store.maxWeight}
                  deliveryFeeInCents={store.deliveryFeeInCents}
                  moneyMinimumInCents={store.moneyMinimumInCents}
                />
              )}
            />
          ))
        )}
      </DeliverySection>
    </Box>
  )
}

export default CateringDeliverySection
