import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router'

import {
  Tooltip,
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'

import {
  AccessTimeOutlined,
  AttachMoney,
  CreditCardOutlined,
  InfoOutlined,
  People,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import currency from 'currency.js'
import { Form, Formik } from 'formik'
import { format } from 'date-fns'
import { object, string, number, array } from 'yup'
import { isEmpty } from 'lodash'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl } from '../util/formatUtils'
import ListActionableDetails from '../components/common/ListActionableDetails'
import Captcha from '../components/common/Captcha'
import { teamPerksRoute, loginRoute } from '../routes/routes'

import { selectTeam, selectDefaultTeamPaymentMethod } from '../redux/selectors/teamSelectors'

import {
  getPerkPaymentHelperText,
  getStepDetails,
  is100PercentOffer,
  formatFrequencyText,
  formatDiscountAmountText,
  formatMembersText,
  formatMembersSecondaryText,
  formatDetailsText,
  formatPaymentMethodText,
  getStepIconColor,
  getProgramAmountDescription,
} from '../components/teams/perks/programUtils'
import { NEW_PAYMENT_METHOD_ID } from '../util/paymentMethods'

import ButtonDialogClose from '../components/teams/common/ButtonDialogClose'
import StepProgramUsageType from '../components/teams/perks/StepProgramUsageType'
import StepProgramAmount from '../components/teams/perks/StepProgramAmount'
import StepProgramMembers from '../components/teams/perks/StepProgramMembers'
import StepProgramDetails from '../components/teams/perks/StepProgramDetails'
import StepAddPaymentMethod from '../components/teams/perks/StepAddPaymentMethod'

import { selectProgramUsageTypes } from '../redux/selectors/teamProgramsSelectors'
import { selectTeamMembers } from '../redux/selectors/teamMembersSelectors'
import {
  loadProgramUsageTypesStart,
  createProgramStart,
  loadProgramDiscountAmountStart,
} from '../redux/modules/teamPrograms'
import { loadTeamMembersStart } from '../redux/modules/teamMembers'
import { enqueueSnackbar } from '../redux/modules/snackbar'
import { loadTeamPaymentMethodsStart } from '../redux/modules/team'
import { formatCentsToPrettyDollars } from '../components/teams/perks/giftCardUtils'
import useTeamPaymentMethod from '../hooks/useTeamPaymentMethod'
import ButtonSubmit from '../components/common/ButtonSubmit'

const useStyles = makeStyles(theme => ({
  perkSummary: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.common.white,
    minWidth: '400px',
    height: '100%',
    marginRight: '32px',
    marginTop: '32px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '250px',
      marginRight: '0px',
      marginTop: '32px',
    },
  },
  perkSteps: {
    minWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'auto',
    },
  },
  recurringPerkPageContainer: {
    flexFlow: 'row wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: theme.spacing(3),
    },
  },
}))

const PageRecurringPerk = ({
  isAuthenticated,
  offerRedeeming,
  team,
  teamMembers,
  isLoadingTeamMembers,
  createProgramStart,
  isCreatingProgram,
  errorCreatingProgram,
  errorAddingProgramMembers,
  loadTeamMembersStart,
  loadProgramUsageTypesStart,
  paymentMethods,
  programUsageTypes,
  isLoadingProgramUsageTypes,
  isAddingProgramMembers,
  isUpdatingDefaultPaymentMethod,
  loadTeamPaymentMethodsStart,
  isLoadingPaymentMethods,
  defaultPaymentMethod,
  loadProgramDiscountAmountStart,
  isLoadingProgramDiscountAmount,
  maxMonthlyPreDiscountTotalInPennies,
}) => {
  const [dialogToShow, setDialogToShow] = useState(null)
  const confirmedTeamMembers = teamMembers.filter(member => member.status === 'CONFIRMED')
  const {
    submitting: isSubmittingPaymentMethod,
    error: errorPaymentMethods,
    handleSubmitTeamPaymentMethod,
  } = useTeamPaymentMethod({
    teamId: team.id,
  })

  const classes = useStyles()
  const history = useHistory()

  const handleItemClicked = perkOption => {
    setDialogToShow(perkOption)
  }

  const getOfferDiscountEndDate = offerDurationInDays => {
    const date = new Date()
    date.setDate(date.getDate() + offerDurationInDays)
    return date
  }

  const offerProgramTemplate = offerRedeeming?.programTemplate ?? {}

  const offerDiscountEndDate =
    offerRedeeming?.discountDurationInDays &&
    getOfferDiscountEndDate(offerRedeeming.discountDurationInDays)

  const isFree = is100PercentOffer(offerRedeeming, offerProgramTemplate.discountAmountInPennies)

  const handleSubmit = values => {
    const path = formatUrl(teamPerksRoute.path, { accountId: team?.id })
    createProgramStart(() => history.push(path), values)
  }

  const handleClose = () => {
    setDialogToShow(null)
  }

  useEffect(() => {
    if (team) {
      loadTeamMembersStart(team.id)
      loadProgramUsageTypesStart()
    }
  }, [loadProgramUsageTypesStart, loadTeamMembersStart, team])

  useEffect(() => {
    if (paymentMethods?.length === 0 && team && isLoadingPaymentMethods) {
      loadTeamPaymentMethodsStart(team.id)
    }
  }, [paymentMethods, loadTeamPaymentMethodsStart, isLoadingPaymentMethods, team])

  if (!isAuthenticated) {
    return <Redirect to={loginRoute.path} />
  }

  const getNextStep = (values, RECURRING_PERK_OPTIONS) => {
    if (!values.usageType) {
      return RECURRING_PERK_OPTIONS[0].id
    } else if (!values.discountAmountInPennies) {
      return RECURRING_PERK_OPTIONS[1].id
    } else if (!values.members) {
      return RECURRING_PERK_OPTIONS[2].id
    } else if (!values.name) {
      return RECURRING_PERK_OPTIONS[3].id
    } else if (
      !isFree &&
      (!values.SelectedCard || (values.SelectedCard === -1 && !defaultPaymentMethod))
    ) {
      return RECURRING_PERK_OPTIONS[4].id
    }
  }

  const handleGetTotal = (newValues, previousValues) => {
    const params = { ...previousValues, ...newValues }

    mainSchema
      .isValid({
        ...params,
      })
      .then(function(valid) {
        if (valid) {
          loadProgramDiscountAmountStart({
            ...params,
            memberIds: params.members,
            accountId: team.id,
          })
        }
      })
  }

  const formatDetailRow = (title, value) => (
    <Box display="flex" justifyContent="space-between" margin={3} marginLeft={0}>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  )

  const mainSchema = object().shape({
    name: string().required(' '),
    discountAmountInPennies: number()
      .moreThan(0)
      .required(' '),
    startDate: string().required(' '),
    usageType: string().required(' '),
    members: array().test('members', ' ', function() {
      return !!this.options.parent.members
    }),
    SelectedCard: number().test('SelectedCard', ' ', function() {
      return this.options.parent.SelectedCard > 0 || isFree
    }),
  })
  return (
    <LayoutInnerPage
      HeaderComponent={
        <HeaderInnerPage
          showBackButton
          backButtonRoute={formatUrl(teamPerksRoute.path, { accountId: team?.id })}
        >
          Create Recurring Perk
        </HeaderInnerPage>
      }
      MainComponent={
        <>
          {paymentMethods && (defaultPaymentMethod || paymentMethods.length === 0) && (
            <Formik
              initialValues={{
                name: offerProgramTemplate.namePlaceholder ?? '',
                discountAmountInPennies: offerProgramTemplate.discountAmountInPennies ?? 0,
                startDate: format(new Date(), 'YYYY-MM-DD'),
                endDateRequested: Boolean(offerDiscountEndDate),
                endDate: offerDiscountEndDate ? format(offerDiscountEndDate, 'YYYY-MM-DD') : '',
                autoEnrollNewMembers: true,
                schedule: offerProgramTemplate.schedule ?? {
                  sundayUsage: false,
                  mondayUsage: true,
                  tuesdayUsage: true,
                  wednesdayUsage: true,
                  thursdayUsage: true,
                  fridayUsage: true,
                  saturdayUsage: false,
                },
                usageType: offerProgramTemplate.usageType ?? '',
                members: null,
                accountDiscountOfferId: offerRedeeming?.accountDiscountOfferId,
                SelectedCard: defaultPaymentMethod?.id ?? NEW_PAYMENT_METHOD_ID,
                notifyUsers: true,
              }}
              onSubmit={handleSubmit}
              validationSchema={mainSchema}
            >
              {props => {
                const { values, errors, submitCount, setFieldValue } = props
                const hasTriedSubmitting = submitCount > 0
                const { title, validationSchema } = getStepDetails(dialogToShow?.step, values)

                const RECURRING_PERK_OPTIONS = [
                  {
                    id: 'FREQUENCY',
                    step: 0,
                    title:
                      formatFrequencyText(values.usageType) ||
                      'How often would you like to give the perk?',
                    secondaryText: 'Perk Frequency',
                    image: (
                      <AccessTimeOutlined
                        color={getStepIconColor(errors.usageType, hasTriedSubmitting)}
                      />
                    ),
                    buttonText: 'EDIT',
                  },
                  {
                    id: 'AMOUNT',
                    step: 1,
                    title:
                      formatDiscountAmountText(values.discountAmountInPennies) ||
                      'How much would you like to give each member?',
                    secondaryText: 'Perk Amount',
                    image: (
                      <AttachMoney
                        color={getStepIconColor(errors.discountAmountInPennies, hasTriedSubmitting)}
                      />
                    ),
                    buttonText: 'EDIT',
                  },
                  {
                    id: 'MEMBERS',
                    step: 2,
                    title:
                      formatMembersText(values.members) ||
                      'Who would you like to give the perk to?',
                    secondaryText: formatMembersSecondaryText(values) || 'Perk Members',
                    image: <People color={getStepIconColor(errors.members, hasTriedSubmitting)} />,
                    buttonText: 'EDIT',
                  },
                  {
                    id: 'DETAILS',
                    step: 3,
                    title: formatDetailsText(values) || 'Perk Details',
                    secondaryText: 'Perk name, start date, end date (optional)',
                    image: (
                      <InfoOutlined color={getStepIconColor(errors.name, hasTriedSubmitting)} />
                    ),
                    buttonText: 'EDIT',
                  },
                  {
                    id: 'PAYMENT_METHOD',
                    step: 4,
                    title:
                      formatPaymentMethodText(defaultPaymentMethod) || 'Default Payment Method',
                    secondaryText: !defaultPaymentMethod ? '' : 'Default Payment Method',

                    image: (
                      <CreditCardOutlined
                        color={getStepIconColor(errors.SelectedCard, hasTriedSubmitting)}
                      />
                    ),
                    buttonText: 'EDIT',
                  },
                ]

                return (
                  <Form>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      className={classes.recurringPerkPageContainer}
                    >
                      <Box marginRight={4} width={'50%'} className={classes.perkSteps}>
                        <Box marginTop={4}>
                          <ListActionableDetails
                            listItems={RECURRING_PERK_OPTIONS}
                            handleClick={handleItemClicked}
                            activeId={getNextStep(values, RECURRING_PERK_OPTIONS)}
                          />
                        </Box>
                        <Dialog open={!!dialogToShow} fullWidth maxWidth="sm">
                          <>
                            <DialogTitle>
                              {title}
                              <ButtonDialogClose onClick={handleClose} />
                            </DialogTitle>
                            {dialogToShow?.id === RECURRING_PERK_OPTIONS[0].id && (
                              <StepProgramUsageType
                                programUsageTypes={programUsageTypes}
                                loading={isLoadingProgramUsageTypes}
                                offerUsageType={offerProgramTemplate.usageType ?? ''}
                                isRedeeming100PercentOffer={isFree}
                                handleClose={handleClose}
                                handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                validationSchema={validationSchema}
                                {...props}
                              />
                            )}
                            {dialogToShow?.id === RECURRING_PERK_OPTIONS[1].id && (
                              <StepProgramAmount
                                isPresetAmount={Boolean(
                                  offerProgramTemplate.discountAmountInPennies,
                                )}
                                isPresetSchedule={Boolean(offerProgramTemplate.schedule)}
                                handleClose={handleClose}
                                handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                validationSchema={validationSchema}
                                programAmountDescription={getProgramAmountDescription(
                                  values.usageType,
                                )}
                                {...props}
                              />
                            )}
                            {dialogToShow?.id === RECURRING_PERK_OPTIONS[2].id && (
                              <StepProgramMembers
                                teamMembers={confirmedTeamMembers}
                                loading={isLoadingTeamMembers}
                                handleClose={handleClose}
                                handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                {...props}
                              />
                            )}
                            {dialogToShow?.id === RECURRING_PERK_OPTIONS[3].id && (
                              <StepProgramDetails
                                isRedeemingOffer={Boolean(offerRedeeming)}
                                offerDiscountEndDate={
                                  offerDiscountEndDate && format(offerDiscountEndDate, 'M/D/YYYY')
                                }
                                handleClose={handleClose}
                                validationSchema={validationSchema}
                                handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                {...props}
                              />
                            )}
                            {dialogToShow?.id === RECURRING_PERK_OPTIONS[4].id && (
                              <StepAddPaymentMethod
                                team={team}
                                paymentMethods={paymentMethods}
                                paymentMethodsError={errorPaymentMethods}
                                helperText={getPerkPaymentHelperText(
                                  offerRedeeming,
                                  props.values.discountAmountInPennies,
                                )}
                                submitting={
                                  isSubmittingPaymentMethod ||
                                  isAddingProgramMembers ||
                                  isUpdatingDefaultPaymentMethod
                                }
                                defaultPaymentMethod={defaultPaymentMethod}
                                error={errorAddingProgramMembers}
                                validationSchema={validationSchema}
                                handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                handleConfirm={values =>
                                  handleSubmitTeamPaymentMethod(values.SelectedCard)
                                }
                                handleClose={handleClose}
                                {...props}
                              />
                            )}
                          </>
                        </Dialog>
                      </Box>
                      <Box
                        width={'35%'}
                        padding={4}
                        minWidth="400px"
                        className={classes.perkSummary}
                      >
                        <Box marginBottom={4}>
                          <Typography variant="h6">Details</Typography>
                        </Box>
                        {formatDetailRow('Type', 'Recurring Perk')}
                        {!!values.usageType &&
                          formatDetailRow('Frequency', formatFrequencyText(values.usageType))}
                        {!!values.discountAmountInPennies &&
                          formatDetailRow(
                            'Amount',
                            `${formatCentsToPrettyDollars(
                              values.discountAmountInPennies,
                            )} per member`,
                          )}
                        {(!!values.members?.length || values.members?.length === 0) &&
                          formatDetailRow('Members', values.members?.length)}
                        <Divider />
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          margin={3}
                          marginLeft={0}
                          marginBottom={0}
                        >
                          <Box display="flex">
                            <Typography variant="body1">Monthly Total</Typography>
                            <Box marginLeft={1}>
                              <Tooltip
                                title={
                                  'Amount listed is 50%-100% of the potential monthly total (excluding new members). You will only be charged for the amount member(s) use of their perk at the end of billing cycle.'
                                }
                              >
                                <InfoOutlined fontSize="small" />
                              </Tooltip>
                            </Box>
                          </Box>
                          {values.members?.length &&
                          maxMonthlyPreDiscountTotalInPennies &&
                          !isLoadingProgramDiscountAmount ? (
                            <Typography>{`${formatCentsToPrettyDollars(
                              maxMonthlyPreDiscountTotalInPennies * 0.5,
                            )} - ${formatCentsToPrettyDollars(
                              maxMonthlyPreDiscountTotalInPennies,
                            )}`}</Typography>
                          ) : (
                            <Typography>
                              {currency(Number(0), { formatWithSymbol: true }).format()}
                            </Typography>
                          )}
                        </Box>
                        <Typography variant="caption">Estimate</Typography>
                        {offerDiscountEndDate && (
                          <Typography color="error" variant="caption">
                            <Box>{`Discount expires ${format(
                              offerDiscountEndDate,
                              'M/DD/YYYY',
                            )}`}</Box>
                          </Typography>
                        )}
                        <Box marginTop={3}>
                          <Captcha />
                        </Box>
                        <Box marginTop={3}>
                          <ButtonSubmit
                            color="primary"
                            width="100%"
                            variant="contained"
                            submitting={isCreatingProgram || isAddingProgramMembers}
                          >
                            Purchase Perk
                          </ButtonSubmit>
                        </Box>
                        {errorCreatingProgram && (
                          <Box margin={2} marginTop={3} marginBottom={3}>
                            <Typography color="error" variant="caption">
                              {errorCreatingProgram}
                            </Typography>
                          </Box>
                        )}
                        {!isEmpty(errors) && hasTriedSubmitting && (
                          <Box margin={2} marginTop={3} marginBottom={3}>
                            <Typography color="error" variant="caption">
                              Please complete all steps before purchasing.
                            </Typography>
                          </Box>
                        )}
                        <Box marginTop={3}>
                          <FormControlLabel
                            name="notifyUsers"
                            label="Notify member(s) via email of this perk"
                            onChange={event => setFieldValue('notifyUsers', event.target.checked)}
                            control={
                              <Checkbox
                                checked={values.notifyUsers}
                                inputProps={{ 'aria-label': 'notify users checkbox' }}
                              />
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Form>
                )
              }}
            </Formik>
          )}
        </>
      }
    />
  )
}

const mapStateToProps = state => {
  const {
    isCreatingProgram,
    errorCreatingProgram,
    isAddingProgramMembers,
    errorAddingProgramMembers,
    programFormValues,
    offerRedeeming,
    isLoadingProgramDiscountAmount,
    programDiscountAmount,
  } = state.teamPrograms
  const { paymentMethods, isUpdatingDefaultPaymentMethod, isLoadingPaymentMethods } = state.team

  return {
    offerRedeeming,
    isAuthenticated: state.user?.isAuthenticated,
    team: selectTeam(state),
    isLoadingProgramUsageTypes: state.teamPrograms.isLoadingProgramUsageTypes,
    programUsageTypes: selectProgramUsageTypes(state),
    isLoadingTeamMembers: state.teamMembers.isLoadingTeamMembers,
    teamMembers: selectTeamMembers(state),
    isCreatingProgram,
    errorCreatingProgram,
    isAddingProgramMembers,
    errorAddingProgramMembers,
    programFormValues,
    paymentMethods,
    isUpdatingDefaultPaymentMethod,
    isLoadingPaymentMethods,
    defaultPaymentMethod: selectDefaultTeamPaymentMethod(state),
    isLoadingProgramDiscountAmount,
    maxMonthlyPreDiscountTotalInPennies: programDiscountAmount?.maxMonthlyPreDiscountTotalInPennies,
  }
}

const mapDispatchToProps = {
  loadProgramUsageTypesStart,
  loadTeamMembersStart,
  enqueueSnackbar,
  createProgramStart,
  loadTeamPaymentMethodsStart,
  loadProgramDiscountAmountStart,
}

PageRecurringPerk.defaultProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PageRecurringPerk)
