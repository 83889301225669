import {
  Backdrop,
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty, isString } from 'lodash'
import React, { useEffect, useState } from 'react'
import scriptLoader from 'react-async-script-loader'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { useCompleteAccountToken } from '../../hooks/auth'
import { useReferralDetails } from '../../hooks/referral'
import { clearLocation, loadLocationStart } from '../../redux/modules/location'
import { search, update } from '../../redux/modules/locationSearch'
import {
  getAddressQueryStringParams,
  selectCurrentLocationWithAddress,
} from '../../redux/modules/selectors'
import { selectQuery } from '../../redux/selectors/routerSelectors'
import { loginRoute, rootRoute, termsAndConditionsRoute } from '../../routes/routes'
import { getAddressByPlaceId } from '../../services/location'
import { LocationType } from '../../util/constants'
import { formatAddress, formatSelectedAddress, formatUrl } from '../../util/formatUtils'
import CollectEmailPanel from '../home/CollectEmailPanel'
import { MAPS_SCRIPT_URL } from '../location-search/LocationSearchBox'
import LocationSearchContainer from '../location-search/LocationSearchContainer'
import DialogSelectLocation from './DialogSelectLocation'
import { getEntryPoint } from './getEntryPoint'

const useStyles = makeStyles(theme => ({
  body: {
    color: 'grey',
  },
  divider: {
    margin: theme.spacing(4),
    width: '100%',
  },
  input: {
    backgroundColor: '#fff',
    display: 'block',
    marginBottom: theme.spacing(4),
  },
  logo: {
    marginBottom: theme.spacing(4),
    maxWidth: '200px',
  },
  register: {
    backgroundColor: theme.palette.background.secondary,
    display: 'flex',
    flexGrow: 1,
    height: '100%',
  },
}))

const FoodsbyLogo = ({ classes }) => (
  <img
    alt="Foodsby Logo"
    className={classes.logo}
    component="img"
    src={`${process.env.REACT_APP_BASE_ORDER_URL}/images/logo-foodsby.png`}
    title="Foodsby Logo"
  />
)

const RegistrationPaneHeader = ({
  classes,
  referralDetails,
  referralEnabledDetails,
  titleText = 'Sign Up',
}) => (
  <Box marginBottom="16px" textAlign="center" width="100%">
    <Typography gutterBottom variant="h4">
      {titleText}
    </Typography>
    {referralDetails && referralEnabledDetails ? (
      <>
        <Typography className={classes.body} display="inline" variant="body2">
          to receive your{' '}
        </Typography>
        <Typography color="secondary" display="inline" gutterBottom variant="subtitle2">
          ${referralEnabledDetails?.rewardAmountPennies / 100} referral credit
        </Typography>
      </>
    ) : (
      <Typography className={classes.body} gutterBottom variant="body2">
        $0 service fee on your first order!
      </Typography>
    )}
  </Box>
)

const RegistrationPaneCollectUserInfo = ({
  classes,
  email,
  entryPoint,
  redirectPath,
  referralCode,
  selectedLocation,
}) => (
  <>
    <Box marginBottom="16px" width="100%">
      <Collapse in={!!selectedLocation}>
        <CollectEmailPanel
          email={email}
          entryPoint={entryPoint}
          hideTOS
          locationId={selectedLocation}
          redirectPath={redirectPath}
          referralCode={referralCode}
        />
      </Collapse>
    </Box>
    <Typography className={classes.body} gutterBottom variant="body2">
      By signing up, you agree to our{' '}
      <a href={termsAndConditionsRoute.path} rel="noopener noreferrer" target="_blank">
        Terms and Conditions Agreement
      </a>
    </Typography>
  </>
)

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const PaneRegistration = ({ isScriptLoadSucceed, isScriptLoaded }) => {
  const queryParams = useQuery()
  const placeId = queryParams.get('placeid')
  const classes = useStyles()
  const dispatchRedux = useDispatch()
  const [selectedLocation, setSelectedLocation] = useState() // Manually selected location

  // Selectors
  const {
    completeAccountToken,
    locationId: locationIdQuery,
    ref_code: branchReferralCode,
    token: sharedReferralCode,
    teamInviteCode,
    createTeam,
  } = useSelector(selectQuery)
  const locationSearchLoading = useSelector(state => state.locationSearch.loading)
  const isLocationLoading = useSelector(state => state.location.isLocationLoading)
  const locationWithAddress = useSelector(state => selectCurrentLocationWithAddress(state)) // Location from redux (needed to load location details)
  const addressQueryParams = useSelector(state => getAddressQueryStringParams(state.router))
  const referralCode = sharedReferralCode || branchReferralCode

  // Hooks
  const { enabledDetails, referralDetails, referralLoading } = useReferralDetails(referralCode)
  const { completeAccountDetails, completeAccountLoading } = useCompleteAccountToken(
    completeAccountToken,
  )

  // Consts
  const formattedAddress = formatAddress(addressQueryParams)
  const isCompleteAccount = !isEmpty(completeAccountDetails)
  const entryPoint = getEntryPoint(referralDetails, teamInviteCode)
  const redirectPath = formatUrl(rootRoute.path, {}, [
    { key: 'teamInviteCode', value: teamInviteCode },
    { key: 'createTeam', value: createTeam },
  ])

  // Effects
  // Location id came from referral, location id, or complete account query
  useEffect(() => {
    const nLocationId = Number(
      locationIdQuery || completeAccountDetails?.locationId || referralDetails?.locationId,
    )
    if (nLocationId > 0) {
      dispatchRedux(loadLocationStart(nLocationId))
    }
  }, [completeAccountDetails, dispatchRedux, locationIdQuery, referralDetails])

  useEffect(() => {
    if (!isEmpty(formattedAddress) && isString(formattedAddress) && isScriptLoaded) {
      dispatchRedux(search(formattedAddress, false))
    }
  }, [dispatchRedux, formattedAddress, isScriptLoaded])

  useEffect(() => {
    const fn = async id => {
      const result = await getAddressByPlaceId(id)
      const formattedAddr = formatAddress(result.address)
      dispatchRedux(search(formattedAddr, false))
    }
    if (isScriptLoaded && placeId) {
      fn(placeId)
    }
  }, [isScriptLoaded, dispatchRedux, placeId])

  useEffect(() => {
    if (
      !isEmpty(referralDetails) &&
      locationWithAddress?.locationType === LocationType.RESIDENTIAL_HOME
    ) {
      dispatchRedux(clearLocation())
    } else if (!isEmpty(locationWithAddress?.address)) {
      dispatchRedux(update(formatAddress(locationWithAddress.address)))
      setSelectedLocation(locationWithAddress)
    }
  }, [dispatchRedux, locationWithAddress, referralDetails])

  return (
    <Paper className={classes.register} elevation={0} square variant="outlined" color="secondary">
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        margin="0 auto"
        padding="64px 32px"
      >
        <FoodsbyLogo classes={classes} />
        {!referralLoading && !completeAccountLoading && (
          <RegistrationPaneHeader
            classes={classes}
            referralDetails={referralDetails}
            referralEnabledDetails={enabledDetails}
            titleText={isCompleteAccount ? 'Complete Your Account' : 'Create account'}
          />
        )}
        <Box marginBottom="16px" width="100%">
          <LocationSearchContainer
            address={
              selectedLocation
                ? formatSelectedAddress(selectedLocation) || formatAddress(selectedLocation)
                : ''
            }
            label={null}
            redirectOverride
          />
        </Box>

        <RegistrationPaneCollectUserInfo
          classes={classes}
          email={completeAccountDetails?.username ?? ''}
          entryPoint={entryPoint}
          redirectPath={redirectPath}
          referralCode={referralCode}
          selectedLocation={selectedLocation?.deliveryLocationId}
        />
        <Divider className={classes.divider} variant="middle" />
        <Typography>
          Have an account?{' '}
          <Link to={{ pathname: loginRoute.path, refer: redirectPath }}>Log In</Link>
        </Typography>
      </Box>

      <DialogSelectLocation
        handleSelect={location => setSelectedLocation(location)}
        isScriptLoaded={isScriptLoaded}
        isScriptLoadSucceed={isScriptLoadSucceed}
      />
      <Backdrop
        open={Boolean(
          completeAccountLoading ||
            referralLoading ||
            ((locationIdQuery || addressQueryParams) &&
              (locationSearchLoading || isLocationLoading)),
        )}
        style={{ zIndex: '1101' }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Paper>
  )
}

export default scriptLoader(MAPS_SCRIPT_URL)(PaneRegistration)
