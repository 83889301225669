import React, { forwardRef, useEffect } from 'react'
import { Box, ClickAwayListener, IconButton, List, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Close } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { isEmpty } from 'lodash'

import ListItemContentCard from './ListItemContentCard'
import { sortContentCards } from './contentCardUtils'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '80px',
    right: '70px',
    width: '450px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      top: '70px',
      right: 0,
      left: 0,
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  closeIconButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1,
  },
  closeIcon: {
    fontSize: '18px',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  listContainer: {
    maxHeight: '600px',
    overflow: 'auto',
  },
}))

const AsideContentCardsComponent = ({
  contentCardsRef: { current: contentCards },
  error,
  onClose,
}) => {
  const classes = useStyles()

  useEffect(() => {
    if (contentCards) {
      window.appboy.logCardImpressions(contentCards, true)
    }
  }, [contentCards])

  const sortedContentCards = sortContentCards(contentCards)
  return (
    <ClickAwayListener onClickAway={onClose}>
      <aside className={classes.root}>
        <IconButton className={classes.closeIconButton} onClick={onClose} size="small">
          <Close className={classes.closeIcon} data-testid="button-close-aside-content-cards" />
        </IconButton>
        <Box marginBottom={3} textAlign="center" position="relative">
          <Typography variant="h6" color="primary">
            Notifications
          </Typography>
        </Box>
        {error && (
          <Alert className={classes.alert} severity="error">
            {error}
          </Alert>
        )}
        <div className={classes.listContainer}>
          <List>
            {sortedContentCards?.map(contentCard => (
              <ListItemContentCard
                key={contentCard.id}
                contentCard={contentCard}
                title={contentCard.title}
                description={contentCard.description}
                imageUrl={contentCard.imageUrl}
                link={{ text: contentCard.linkText, url: contentCard.url }}
                viewed={contentCard.viewed}
                pinned={contentCard.pinned}
              />
            ))}
          </List>
          {isEmpty(contentCards) && <NoNotification />}
        </div>
      </aside>
    </ClickAwayListener>
  )
}

const NoNotification = () => {
  return (
    <Box textAlign="center">
      <Typography variant="h6" gutterBottom>
        No New Notifications
      </Typography>
      <Typography variant="body2" gutterBottom>
        Check back for offers, feature updates, and promotions.
      </Typography>
    </Box>
  )
}

export default forwardRef(function AsideContentCards(props, ref) {
  return <AsideContentCardsComponent {...props} contentCardsRef={ref} />
})
