import { api as baseApi } from '@foodsby/webapp-jwt'
import LRUCache from 'lru-cache'

import { logException } from '../util/errorUtils'
import { camelcaseKeys, pascalcaseKeys } from '../util/keys'

export const FOODSBY_BASE_URL = process.env.REACT_APP_FOODSBY_BASE_URL || ''
export const API_MONO = '/api-monolith'

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET

export const api = {
  ...baseApi,
  request: (endpoint, method, body, config = {}) => {
    config = {
      baseURL: FOODSBY_BASE_URL,
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
      ...config,
    }
    return baseApi
      .request(endpoint, method, body, config)
      .then(data => camelize(data))
      .catch(error => {
        resetCaches()
        return Promise.reject(error)
      })
  },
}

const camelize = data => {
  if (data && !data.access_token && typeof data === 'object') {
    try {
      data = camelcaseKeys(data, { deep: true })
    } catch (ex) {
      logException(ex)
    }
  }
  return data
}

/**
 * Per service Caches
 */
export const subscriptionsCache = new LRUCache({ maxAge: 60000 }) // 1 minute

export const connectionsCache = new LRUCache({ maxAge: 90000 }) // 90 seconds
export const monolithCache = new LRUCache({ maxAge: 90000 }) // 90 seconds

export const shareablesCache = new LRUCache({ maxAge: 300000 }) // 5 minutes
export const favoritesCache = new LRUCache({ maxAge: 300000 }) // 5 minutes

export const resetCaches = () => {
  subscriptionsCache.reset()
  connectionsCache.reset()
  monolithCache.reset()
  shareablesCache.reset()
  favoritesCache.reset()
}

/**
 * API services
 */
export const searchNearbyLocations = (address, coords) => {
  return api.get(
    `${API_MONO}/location/search?street=${encodeURIComponent(
      address.street,
    )}&city=${encodeURIComponent(address.city)}&state=${encodeURIComponent(
      address.state,
    )}&zip=${encodeURIComponent(address.zip)}&latitude=${encodeURIComponent(
      coords.lat,
    )}&longitude=${encodeURIComponent(coords.lng)}`,
  )
}

export const deliveringRestaurants = locationId =>
  api.get(`${API_MONO}/location/${locationId}/delivering-restaurants`, null, {
    cache: monolithCache,
  })

export const patchThankYouModal = message =>
  api.patch(`${API_MONO}/user/mobile-popup`, pascalcaseKeys(message))

export const loadIsGroupOrderLocation = locationId =>
  api.get(`api/v1/group-order/location/${locationId}/supported`, null, {
    cache: monolithCache,
  })

export const loadLocationById = locationId =>
  api.get(`${API_MONO}/location/${locationId}`, null, { cache: monolithCache })

export const loadPastOrdersByStoreId = storeId =>
  api.get(`${API_MONO}/store/${storeId}/past-orders?dedup=true`, null, {
    cache: monolithCache,
  })

export const loadPastCateringOrdersByStoreId = storeId =>
  api.get(`${API_MONO}/store/${storeId}/past-catering-orders?dedup=true`, null, {
    cache: monolithCache,
  })

export const loadMenuByStoreId = storeId =>
  api.get(`${API_MONO}/store/${storeId}/menu`, null, { cache: monolithCache })

export const loadCateringMenuByStoreId = storeId =>
  api.get(`${API_MONO}/store/${storeId}/catering-menu`, null, { cache: monolithCache })

export const loadMenuItem = menuItemId =>
  api.get(`${API_MONO}/menu/item/${menuItemId}`, null, { cache: monolithCache })

export const loadOrderCart = dropoffId =>
  api.get(`${API_MONO}/v2/cart/dropoff/${dropoffId}`).catch(error => {
    if (error.message === 'Request failed with status code 404') {
      return {} // Makes sure the promise is resolved, so the chain continues
    }
    throw error // Otherwise, rethrow to keep the Promise rejected
  })

export const loadCateringOrderCart = (storeId, locationId) =>
  api
    .get(`${API_MONO}/v2/cart/catering?storeId=${storeId}&locationId=${locationId}`)
    .catch(error => {
      if (error.message === 'Request failed with status code 404') {
        return {} // Makes sure the promise is resolved, so the chain continues
      }
      throw error // Otherwise, rethrow to keep the Promise rejected
    })

export const loadReserveOrderCart = (storeId, locationId) =>
  api
    .get(`${API_MONO}/v2/cart/reserve?storeId=${storeId}&locationId=${locationId}`)
    .catch(error => {
      if (error.message === 'Request failed with status code 404') {
        return {} // Makes sure the promise is resolved, so the chain continues
      }
      throw error // Otherwise, rethrow to keep the Promise rejected
    })

export const addItemsToCart = (dropoffId, menuItems) =>
  api.post(`${API_MONO}/v2/cart/dropoff/${dropoffId}`, pascalcaseKeys(menuItems))

export const addItemsToCateringCart = (storeId, locationId, menuItems) =>
  api.post(
    `${API_MONO}/v3/cart/catering?storeId=${storeId}&locationId=${locationId}`,
    pascalcaseKeys(menuItems),
  )

export const addItemsToReserveCart = (storeId, locationId, menuItems) =>
  api.post(
    `${API_MONO}/v3/cart/reserve?storeId=${storeId}&locationId=${locationId}`,
    pascalcaseKeys(menuItems),
  )

export const updateOrderItemWithQuantityApi = (orderItemId, item) =>
  api.put(`${API_MONO}/v3/cart/items/${orderItemId}`, pascalcaseKeys(item))

export const removeItemFromCart = (dropoffId, menuItem) =>
  api.delete(`${API_MONO}/cart/dropoff/${dropoffId}`, pascalcaseKeys(menuItem))

export const removeItemFromCateringCart = (storeId, locationId, menuItem) =>
  api.delete(
    `${API_MONO}/cart/catering?storeId=${storeId}&locationId=${locationId}`,
    pascalcaseKeys(menuItem),
  )

export const removeItemFromReserveCart = (storeId, locationId, menuItem) =>
  api.delete(
    `${API_MONO}/cart/reserve?storeId=${storeId}&locationId=${locationId}`,
    pascalcaseKeys(menuItem),
  )

export const deleteCateringOrder = (storeId, locationId) =>
  api.delete(`${API_MONO}/v2/cart/catering?storeId=${storeId}&locationId=${locationId}`)

export const deleteReserveOrder = (storeId, locationId) =>
  api.delete(`${API_MONO}/v2/cart/reserve?storeId=${storeId}&locationId=${locationId}`)

export const loadAvailableStoredCurrency = () =>
  api.get(`${FOODSBY_BASE_URL}/api/v1/wallets/stored-currency/balance`)
export const loadAvailableCredits = () => api.get(`${FOODSBY_BASE_URL}/api/v1/wallets/balances`)

export const loadPaymentMethods = () =>
  api.get(`${FOODSBY_BASE_URL}/api/v1/credit-cards/payment-methods`)

export const updatePaymentMethod = (id, data) =>
  api.put(`${FOODSBY_BASE_URL}/api/v1/credit-cards/payment-methods/${id}`, data)

export const updateDefaultPaymentMethodAtCheckout = (id, data) =>
  api.patch(
    `${FOODSBY_BASE_URL}/api/v1/credit-cards/payment-methods/${id}/setDefaultForCheckout`,
    data,
  )

export const deletePaymentMethod = id =>
  api.delete(`${FOODSBY_BASE_URL}/api/v1/credit-cards/payment-methods/${id}`)

export const createSetupIntent = data =>
  api.post(`${FOODSBY_BASE_URL}/api/v1/credit-cards/payment-methods/setup-intent`, data)

export const checkoutOrder = id =>
  api.patch(`${API_MONO}/v1/orders/${id}/process`, {
    orderPlatform: 'WEB',
  })

export const validateOrder = orderId => api.get(`${API_MONO}/v1/orders/${orderId}/validate`)

export const loadOrderHistory = (page = 1, pageSize = 5) =>
  api.get(`${API_MONO}/order/history?page=${page}&pageSize=${pageSize}`)

export const attemptResetPassword = forgotPasswordFormData =>
  api.post(`${FOODSBY_BASE_URL}/api/v1/uaa/passwords/forgot`, forgotPasswordFormData)

export const attemptCompleteResetPassword = completeResetPasswordFormData =>
  api.post(`${FOODSBY_BASE_URL}/api/v1/uaa/passwords/reset`, completeResetPasswordFormData)

export const attemptChangePassword = changePasswordFormData =>
  api.put(`${FOODSBY_BASE_URL}/api/v1/uaa/passwords/current`, changePasswordFormData)

export const reorderPastOrder = (dropoffId, pastOrderId) =>
  api.post(`${API_MONO}/deliveries/dropoff/${dropoffId}/reorder/${pastOrderId}`)

export const reorderPastCateringOrder = (storeId, locationId, pastOrderId) =>
  api.post(
    `${API_MONO}/v2/cart/catering/reorder/${pastOrderId}?storeId=${storeId}&locationId=${locationId}`,
  )

export const reorderPastReserveOrder = (storeId, locationId, pastOrderId) =>
  api.post(
    `${API_MONO}/v2/cart/reserve/reorder/${pastOrderId}?storeId=${storeId}&locationId=${locationId}`,
  )

export const loadOrderByDate = (date, deliveryLocationId) =>
  api.get(`${API_MONO}/order/day?day=${date}&locationId=${deliveryLocationId}`, null, {
    cache: monolithCache,
  })

export const loadFutureOrders = (currentDate, deliveryLocationId, numberOfDaysInFuture) =>
  api.get(
    `${API_MONO}/order/day?day=${currentDate}&locationId=${deliveryLocationId}&duration=${numberOfDaysInFuture}`,
    null,
    {
      cache: monolithCache,
    },
  )

export const loadStoreById = storeId =>
  api.get(`${API_MONO}/v2/stores/${storeId}`, null, { cache: monolithCache })

export const loadLocationCateringStoreById = (storeId, locationId) =>
  api.get(`${API_MONO}/location/${locationId}/catering-schedule/${storeId}`, null, {
    cache: monolithCache,
  })

export const getReserveStoresForLocation = locationId =>
  api.get(`${API_MONO}/location/${locationId}/reserve-schedule`, null, { cache: monolithCache })

export const loadLocationReserveStoreById = (storeId, locationId) =>
  api.get(`${API_MONO}/location/${locationId}/reserve-schedule/${storeId}`, null, {
    cache: monolithCache,
  })

export const loadOrderById = orderId =>
  api.get(`${FOODSBY_BASE_URL}/api/v1/salad/web/orders/${orderId}/detail`)

export const loadPaymentOptions = () => api.get(`${API_MONO}/payments`)
export const deletePaymentOption = ccProfileId => api.delete(`${API_MONO}/payments/${ccProfileId}`)

export const postSurveyResults = survey =>
  api.post(`${FOODSBY_BASE_URL}/api/v1/achievements/survey`, survey)

export const getCaptcha = () =>
  new Promise((resolve, reject) => {
    try {
      window.grecaptcha.ready(function() {
        window.grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'submit' })
          .then(async function(token) {
            resolve(token)
          })
      })
    } catch (err) {
      reject(err)
    }
  })

export const getOrderRewardsByOrderIdApi = orderId =>
  api.get(`${FOODSBY_BASE_URL}/api/v1/deliveries/rewards/orders/${orderId}`)

export const getRewardsForDeliveryDropoffByIdApi = deliveryDropoffId =>
  api.get(`${FOODSBY_BASE_URL}/api/v1/deliveries/rewards/delivery-dropoffs/${deliveryDropoffId}`)
