import { getRewardsForDeliveryDropoffByIdApi } from '../../api/api'
import { createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const DELIVERY_DROPOFF_REWARDS_SET =
  'foodsby/deliveryDropoffRewards/DELIVERY_DROPOFF_REWARDS_SET'

export const setDeliveryDropoffRewards = createAsyncAction(DELIVERY_DROPOFF_REWARDS_SET)

export const loadDeliveryDropoffRewardsStart = deliveryDropoffId => {
  return dispatch => {
    dispatch(setDeliveryDropoffRewards(getRewardsForDeliveryDropoffByIdApi(deliveryDropoffId)))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [PENDING(DELIVERY_DROPOFF_REWARDS_SET)]: state => {
    return {
      ...state,
      isLoadingDeliveryDropoffRewards: true,
    }
  },
  [FULFILLED(DELIVERY_DROPOFF_REWARDS_SET)]: (state, action) => {
    return {
      ...state,
      maximumPercentage: action.payload.maximumPercentage ?? null,
      currentRewardsPercentage: action.payload.currentRewardsPercentage ?? null,
      maximumOrders: action.payload.maximumOrders ?? null,
      nextRewardsPercentage: action.payload.nextRewardsPercentage ?? null,
      numberOfOrdersPerPercentage: action.payload.numberOfOrdersPerPercentage ?? null,
      errorLoadingDeliveryDropoffRewards: undefined,
      isLoadingDeliveryDropoffRewards: false,
    }
  },
  [REJECTED(DELIVERY_DROPOFF_REWARDS_SET)]: (state, action) => {
    return {
      ...state,
      maximumPercentage: null,
      currentRewardsPercentage: null,
      maximumOrders: null,
      nextRewardsPercentage: null,
      numberOfOrdersPerPercentage: null,
      errorLoadingDeliveryDropoffRewards: action.payload,
      isLoadingDeliveryDropoffRewards: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  maximumPercentage: undefined,
  currentRewardsPercentage: undefined,
  maximumOrders: undefined,
  nextRewardsPercentage: undefined,
  isLoadingDeliveryDropoffRewards: false,
  numberOfOrdersPerPercentage: undefined,
  errorLoadingDeliveryDropoffRewards: undefined,
}

export default function deliveryDropoffRewards(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
