import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { themeConWeb } from '../../util/modernThemeConweb'
import SectionHeaderWrapper from './SectionHeaderWrapper'

const CateringHeader = ({ loading }) => {
  if (loading) {
    return (
      <Box style={{ paddingBottom: 4, paddingTop: 4 }}>
        <Skeleton height={70} width={250} />
      </Box>
    )
  }
  return (
    <SectionHeaderWrapper>
      <Typography
        variant="h6"
        style={{
          color: themeConWeb.color.primaryType,
          fontWeight: 700,
          fontSize: themeConWeb.fontSizes.basePlus3,
          lineHeight: themeConWeb.fontSizes.basePlus2,
        }}
      >
        Catering
      </Typography>
    </SectionHeaderWrapper>
  )
}

export default CateringHeader
