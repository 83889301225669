import React from 'react'
import { ArticleLarge } from '../common/Layout'
import ContactUsSidebar from '../contact-us/ContactUsSidebar'
import { HeaderInnerPage } from '../../layouts/LayoutInnerPage'
import { formatUrl } from '../../util/formatUtils'
import { locationRoute } from '../../routes/routes'
import { orderStateText } from '../home/utils/orderUtils'
import RewardsAndReferralsLinkCard from '../common/RewardsAndReferralsLinkCard'
import { CoworkersAndCreditsCard } from './CoworkersAndCreditsCard'
import { OrderSummaryCard } from './OrderSummaryCard'

export const ConfirmationComponent = ({
  location,
  friendsOrdered,
  avatarId,
  firstName,
  lastName,
  deliveryDropoff,
  isLoadingFriendsOrders,
  autoFavorites,
  closeAutoFavorite,
  isLoadingFavorites,
  loading,
  locationId,
  order,
  reasons,
  showOrderSummary,
  submitTicket,
  submitting,
  toggleShowOrderSummary,
  currentRewardsInCents,
  currentRewardsPercentage,
  rewardsEligibleSubtotalInCents,
  rewardsDistributed,
  deliveryDropoffRewards,
  errorLoadingOrderRewards,
  loadingOrderRewards,
  rewards,
  isLoadingRewards,
}) => {
  const showCoworkersAndCredits = order && !order.statusText.includes(orderStateText.CANCELED)
  const isCatering = deliveryDropoff.type === 'Catering'
  const showRewardsAndReferralsCard =
    !isCatering &&
    order &&
    deliveryDropoffRewards &&
    order.rewardsCreditsEarned != null &&
    rewards.length > 0 &&
    !order?.isPastCutoff &&
    !rewardsDistributed

  return (
    <ArticleLarge>
      <HeaderInnerPage
        showBackButton
        backButtonRoute={formatUrl(locationRoute.path, { locationId })}
      >
        Home
      </HeaderInnerPage>
      <OrderSummaryCard
        order={order}
        loading={loading}
        isLoadingFavorites={isLoadingFavorites}
        submitting={submitting}
        showOrderSummary={showOrderSummary}
        toggleShowOrderSummary={toggleShowOrderSummary}
        deliveryDropoff={deliveryDropoff}
        location={location}
        autoFavorites={autoFavorites}
      />
      {showRewardsAndReferralsCard && (
        <RewardsAndReferralsLinkCard
          storeName={order.storeName}
          maximumPercentage={deliveryDropoffRewards.maximumPercentage}
          maximumOrders={deliveryDropoffRewards.maximumOrders}
          loading={!order || loading || !rewards || isLoadingRewards || loadingOrderRewards}
        />
      )}
      <div className="row" style={{ margin: '0 -8px' }}>
        {showCoworkersAndCredits && (
          <div className="col-xs-12 col-md-6">
            <CoworkersAndCreditsCard
              order={order}
              friendsOrdered={friendsOrdered}
              deliveryDropoff={deliveryDropoff}
              isLoadingFriendsOrders={isLoadingFriendsOrders}
              loading={loading}
              isLoadingFavorites={isLoadingFavorites}
              submitting={submitting}
              avatarId={avatarId}
              firstName={firstName}
              deliveryDropoffRewards={deliveryDropoffRewards}
              errorLoadingOrderRewards={errorLoadingOrderRewards}
              loadingOrderRewards={loadingOrderRewards}
              currentRewardsInCents={currentRewardsInCents}
              currentRewardsPercentage={currentRewardsPercentage}
              rewardsEligibleSubtotalInCents={rewardsEligibleSubtotalInCents}
              rewardsDistributed={rewardsDistributed}
              lastName={lastName}
            />
          </div>
        )}
        <aside
          className={`col-xs-12 ${showCoworkersAndCredits && 'col-md-6'} confirmation-contact-us`}
        >
          <ContactUsSidebar
            className="contact-us-form"
            locationId={locationId}
            order={order}
            orderHistorySize
            reasons={reasons}
            showForm
            submitticket={submitTicket}
          />
        </aside>
      </div>
    </ArticleLarge>
  )
}
