import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { selectCurrentUser } from '../redux/modules/selectors'
import { selectQuery } from '../redux/selectors/routerSelectors'
import { rootRoute, registerRoute, locationRoute } from '../routes/routes'
import { formatUrl } from '../util/formatUtils'

const TEAM_INVITE_CODE_KEY = 'teamInviteCode'

const RedirectToLocation = ({ locationId, queryParams }) => (
  <Redirect to={formatUrl(locationRoute.path, { locationId }, queryParams)} />
)

const RedirectToRegister = ({ queryParams }) => (
  <Redirect to={formatUrl(registerRoute.path, {}, queryParams)} />
)

const PageTeamInvite = ({ inviteCode, locationId, currentUser, isCurrentUserLoading }) => {
  if (!inviteCode) {
    return <Redirect to={rootRoute.path} />
  }

  const queryParams = [{ key: TEAM_INVITE_CODE_KEY, value: inviteCode }]
  if (locationId) {
    return <RedirectToLocation locationId={locationId} queryParams={queryParams} />
  }

  if (!currentUser && !isCurrentUserLoading) {
    return <RedirectToRegister queryParams={queryParams} />
  }

  if (currentUser && !isCurrentUserLoading) {
    return (
      <RedirectToLocation locationId={currentUser.deliveryLocationId} queryParams={queryParams} />
    )
  }

  return null
}

const mapStateToProps = state => {
  const { inviteCode, locationId } = selectQuery(state)
  const currentUser = selectCurrentUser(state)
  const { isCurrentUserLoading } = state.user

  return {
    inviteCode,
    locationId,
    currentUser,
    isCurrentUserLoading,
  }
}

export default connect(mapStateToProps)(PageTeamInvite)
