import { Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import scriptLoader from 'react-async-script-loader'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { MAPS_SCRIPT_URL } from '../components/location-search/LocationSearchBox'
import PaneRegistration from '../components/register/PaneRegistration'
import RegistrationPoints from '../components/register/RegistrationPoints'
import { rootRoute } from '../routes/routes'

const useStyles = makeStyles(theme => ({
  info: {
    backgroundImage: `url(https://images.foodsby.com/conweb/register-bg-food.jpeg)`,
    backgroundSize: 'cover',
  },
  register: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '500px',
    },
  },
  root: {
    flex: 1,
    height: '100%',
  },
}))

export const PageRegister = ({ isScriptLoadSucceed, isScriptLoaded }) => {
  const { isAuthenticated, isCurrentUserLoading } = useSelector(state => state.user)
  const classes = useStyles()
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'))

  return isAuthenticated && !isCurrentUserLoading ? (
    <Redirect to={rootRoute.path} />
  ) : (
    <Grid className={classes.root} container>
      <Grid container wrap={matches ? 'nowrap' : 'wrap'}>
        <Grid className={classes.register} item xs={12}>
          <PaneRegistration
            isScriptLoaded={isScriptLoaded}
            isScriptLoadSucceed={isScriptLoadSucceed}
          />
        </Grid>
        <Grid
          className={classes.info}
          container
          direction="column"
          item
          justifyContent="center"
          xs={12}
        >
          <RegistrationPoints maxWidth="500px" padding="64px" />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default scriptLoader(MAPS_SCRIPT_URL)(PageRegister)
