import React from 'react'
import { Typography } from '@material-ui/core'
import { Panel } from '../common/Layout'
import { useMyReferralWithEnabledDetails } from '../../hooks/referral'
import { CopyReferral } from '../community/Referral'

export const PanelReferral = () => {
  const { referralAndEnabledDetails } = useMyReferralWithEnabledDetails()
  const referralReward =
    referralAndEnabledDetails?.referralEnabledDetails?.rewardAmountPennies / 100
  return (
    <Panel>
      <Typography style={{ fontWeight: 500, fontSize: '32px', textAlign: 'center' }}>
        Give $5, Earn $5
      </Typography>
      <p style={{ paddingBottom: '10px', textAlign: 'center', paddingTop: '10px' }}>
        {`Invite your friends to Foodsby and you’ll both get a $${referralReward} credit when
            they place their first order and it's delivered.`}
      </p>
      <CopyReferral referralAndEnabledDetails={referralAndEnabledDetails} />
    </Panel>
  )
}

export default PanelReferral
