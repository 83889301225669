import { Box, Grid, Typography, Card } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadAvailableStoredCurrency } from '../api/api'
import { CreditsTable, WalletSummary } from '../components/wallet'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { loadAvailableProgramStart } from '../redux/modules/checkout'
import { loadAvailableCreditsStart } from '../redux/modules/creditHistory'
import { loadPaymentOptionsStart } from '../redux/modules/profile'
import { homeRoute } from '../routes/routes'
import { enqueueSnackbar } from '../redux/modules/snackbar'
import PanelReferral from '../components/profile/PanelReferral'

const PagePerksCredits = () => {
  const [isLoading, setIsLoading] = useState(undefined)
  const [storedCurrency, setStoredCurrency] = useState(null)
  const dispatchRedux = useDispatch()
  const availableCredits = useSelector(state => state?.creditHistory?.availableCredits)
  const availableProgram = useSelector(state => state?.checkout?.programInfo)

  useEffect(() => {
    async function loadData() {
      try {
        setIsLoading(true)
        dispatchRedux(loadPaymentOptionsStart())
        dispatchRedux(loadAvailableCreditsStart())
        dispatchRedux(loadAvailableProgramStart(format(new Date(), 'YYYY-MM-DD')))
        const giftAmount = await loadAvailableStoredCurrency()
        setStoredCurrency(giftAmount)
      } catch (ex) {
        dispatchRedux(
          enqueueSnackbar({
            message: `An error occurred. ${ex?.message}`,
          }),
        )
      } finally {
        setIsLoading(false)
      }
    }
    loadData()
  }, [dispatchRedux])

  return (
    <LayoutInnerPage
      HeaderComponent={
        <HeaderInnerPage showBackButton backButtonRoute={homeRoute.path}>
          Back
        </HeaderInnerPage>
      }
      MainComponent={
        <>
          {isLoading && <Skeleton />}

          {!isLoading && (
            <>
              <Grid container justifyContent="center">
                <Grid item md={10} xs={12}>
                  <Card>
                    <PanelReferral />
                  </Card>
                  <Box marginBottom={5} marginTop={3}>
                    <WalletSummary
                      credits={availableCredits}
                      gifts={storedCurrency}
                      program={availableProgram}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item md={10} xs={12}>
                  <Box marginBottom={3}>
                    <Typography component="h1" variant="h6">
                      Credit History
                    </Typography>
                  </Box>
                  <CreditsTable />
                </Grid>
              </Grid>
            </>
          )}
        </>
      }
    />
  )
}

export default PagePerksCredits
