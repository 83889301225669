import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { themeConWeb } from '../../util/modernThemeConweb'
import { useMyReferralWithEnabledDetails } from '../../hooks/referral'
import { ConfirmationCopyReferral } from '../community/Referral'
import { SpinnerCard } from './Layout'

const RewardsAndReferralsLinkCard = ({ maximumPercentage, maximumOrders, storeName, loading }) => {
  const { referralAndEnabledDetails } = useMyReferralWithEnabledDetails()

  return (
    <SpinnerCard spinnerActive={loading} style={{ border: '6px solid #6E8D28' }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus4, fontWeight: 700 }}>
          Order Together, Earn Together
        </Typography>
        <Box marginBottom={'1.5em'}>
          <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus2 }}>
            Everyone earns up to{' '}
            <span style={{ fontWeight: 700, fontSize: '22px' }}>{maximumPercentage * 100}%</span>{' '}
            when <span style={{ fontWeight: 700, fontSize: '22px' }}>{maximumOrders}+ </span>
            customers order from this delivery!
          </Typography>
        </Box>

        <ConfirmationCopyReferral
          referralAndEnabledDetails={referralAndEnabledDetails}
          storeName={storeName}
        />
      </Box>
    </SpinnerCard>
  )
}

export default RewardsAndReferralsLinkCard
